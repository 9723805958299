import React from 'react'
import { CellStudentName, CellTeacherName } from './styles'
import { Download, BackButton, Buttons, Table } from '../../styles';

export default function Result({ student, data, onBackPress }) {

  function incidentNumber(teacher, cause) {
    const incident = data.incidents.find(incident =>
      incident.teacher_id === teacher.CODPROF && incident.idcause === cause.idcause)

    return incident?.qtde || 0
  }

  function foulNumber(teacher) {
    const foul = data.fouls.find(foul => foul.CODPROF === teacher.CODPROF)
    return foul?.qtde || 0
  }

  return (
    <>
      <Buttons>
        <Download
          id="test-table-xls-button"
          className="download-table-xls-button"
          table="table-to-xls"
          filename="relatorio"
          sheet="tablexls"
          buttonText="Download" />

        <BackButton onClick={onBackPress}>Voltar</BackButton>
      </Buttons>

      <Table cellPadding={0} cellSpacing={0} id='table-to-xls' >
        <tbody>
          <tr>
            <td>&nbsp;</td>
            <CellStudentName colSpan={data.causes.length + 1}>{student.label}</CellStudentName>
          </tr>
          <tr>
            <td>&nbsp;</td>
            {data.causes.map(cause =>
              <td key={cause.idcause}>
                {cause.name}
              </td>
            )}
            <td>Falta</td>
          </tr>
          {data.teachers.map(teacher =>
            <tr key={teacher.CODPROF}>
              <CellTeacherName>{teacher.NOME}</CellTeacherName>
              {data.causes.map(cause =>
                <td key={cause.idcause}>
                  {incidentNumber(teacher, cause)}
                </td>
              )}
              <td>
                {foulNumber(teacher)}
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  )
}
