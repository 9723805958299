import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import TopNavigation from "../../navigation/TopNavigation";
import {
  withStyles,
  Typography,
  Paper,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Checkbox,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from "@material-ui/core";

//import Loader from 'react-loader';

import { connect } from "react-redux";
import { MuiPickersUtilsProvider, DatePicker } from "material-ui-pickers";
import MomentUtils from "@date-io/moment";

import {
  StyledFormControl,
  StyledFormControlFullWdith,
} from "../../../css/styled";

import { MySnackbar } from "../../messages/MySnackBar";

import moment from "moment";
import "moment/locale/pt-br";
import api from "../../../api";

import styles from "./styles";

const INITIAL_STATE = {
  dates: [],
  classes: [],
  subjects: [],
  turmadiscs: [],
  subjectTimes: [],
  lessonPlans: [],
  students: [],

  data: {
    class: "",
    idturmadisc: "",
    idsHorarioTurma: [],
    date: moment.utc(),
    students: [],
  },
  success: false,
  loading: false,
  error: "",
};

class RollCall extends Component {
  state = INITIAL_STATE;

  componentDidMount() {
    api.incident.fetchTeacherClasses().then((turmadiscs) => {
      const classes = turmadiscs.filter((elem, index, self) => {
        for (let x = 0; x < self.length; x++) {
          if (elem.class === self[x].class) {
            return self.indexOf(elem) === x;
          }
        }
        return false;
      });
      this.setState({ classes, turmadiscs });
    });
  }

  subjectTimes = (lessonPlans, date) => {
    const formatedDate = date.format("YYYY-MM-DD");
    const subjectTimes = lessonPlans.filter(
      (lessonPlan) => lessonPlan.date === formatedDate
    );
    return subjectTimes;
  };

  onClassChange = (e) => {
    const { data, turmadiscs } = this.state;
    const subjects = turmadiscs.filter((elem) => elem.class === e.target.value);

    this.setState({
      error: "",
      success: false,
      students: [],
      subjects,
      data: { ...data, idturmadisc: "", class: e.target.value },
    });
  };

  onSubjectChange = async (e) => {
    const { data } = this.state;
    this.setState({
      error: "",
      success: false,
      students: [],
      lessonPlans: [],
      subjectTimes: [],
      loading: true,
      data: { ...data, [e.target.name]: e.target.value, students: [] },
    });

    const lessonPlans = await api.rollcalls.fetchLessonPlans(e.target.value);
    let selectedDate = null;
    let dates = [];
    lessonPlans.map((lessonPlan) => {
      if (moment(lessonPlan.date).isBefore(moment()) && (selectedDate == null || moment(selectedDate).isBefore(moment(lessonPlan.date)))) {
        selectedDate = moment(lessonPlan.date);
      }

      if (dates.indexOf(lessonPlan.date) === -1) dates.push(lessonPlan.date);

      return true;
    });

    const subjectTimes = this.subjectTimes(lessonPlans, selectedDate);

    const students = await api.incident.fetchStudentsTurmadisc(e.target.value);
    this.setState({
      students,
      dates,
      lessonPlans,
      subjectTimes,
      data: { ...this.state.data, date: selectedDate },
      loading: false,
    });
  };

  onDateChange = (date) => {
    const { lessonPlans } = this.state;
    const subjectTimes = this.subjectTimes(lessonPlans, date);

    this.setState({
      subjectTimes,
      data: { ...this.state.data, date, idsHorarioTurma: [], students: [] },
    });
  };

  onStudentClick = (student) => {
    let { students } = this.state.data;
    const currentIndex = students.indexOf(student.ra);
    if (currentIndex === -1) {
      students = [...students, student.ra];
    } else {
      students = students.filter((ra) => ra !== student.ra);
    }
    this.setState({ data: { ...this.state.data, students } });
  };

  onSubjectTimeClick = async (time) => {
    let { idsHorarioTurma, date, idturmadisc } = this.state.data;
    const currentIndex = idsHorarioTurma.indexOf(time.idHorarioTurma);
    if (currentIndex === -1) {
      idsHorarioTurma = [...idsHorarioTurma, time.idHorarioTurma];
    } else {
      idsHorarioTurma = idsHorarioTurma.filter(
        (idHorarioTurma) => idHorarioTurma !== time.idHorarioTurma
      );
    }

    let students = [];

    if (idsHorarioTurma.length === 1) {
      const dateFormatted = date.format("YYYY-MM-DD");
      const { data } = await api.axios.get(
        `/rollcalls/${dateFormatted}/${idturmadisc}/${idsHorarioTurma[0]}`
      );
      students = data.map((student) => student.ra);
    }

    this.setState({ data: { ...this.state.data, idsHorarioTurma, students } });
  };

  disableDate = (datePicker) => {
    const { dates } = this.state;
    return !dates.find((date) => date === datePicker.format("YYYY-MM-DD"));
  };

  onSubmit = async (e) => {
    e.preventDefault();
    this.setState({ error: "", loading: true });
    const { data } = this.state;
    try {
      await api.rollcalls.store(data);
      this.setState({ success: true, loading: false });

      setTimeout(function () {
        window.location.reload();
      }, 2000);
    } catch (err) {
      const message =
        err.response.data.message !== undefined
          ? err.response.data.message
          : "Erro ao gravar. Tente novamente, por favor.";
      this.setState({ error: message, loading: false });
    }
  };

  render() {
    const { classes } = this.props;
    const {
      data,
      subjects,
      subjectTimes,
      dates,
      students,
      loading,
      success,
      error,
    } = this.state;

    return (
      <Fragment>
        <TopNavigation history={this.props.history} />
        <Paper className={classes.root}>
          <Typography variant="h5" component="h3">
            Chamada
          </Typography>

          <form className={classes.form} onSubmit={this.onSubmit}>
            <StyledFormControlFullWdith>
              <InputLabel htmlFor="class">Turma</InputLabel>
              <Select
                value={data.class}
                inputProps={{ name: "class", id: "class" }}
                onChange={this.onClassChange}
              >
                {this.state.classes.map((item, index) => (
                  <MenuItem key={index} value={item.class}>
                    {item.class}
                  </MenuItem>
                ))}
              </Select>
            </StyledFormControlFullWdith>

            <StyledFormControlFullWdith>
              <InputLabel htmlFor="idturmadisc">Disciplina</InputLabel>
              <Select
                value={data.idturmadisc}
                inputProps={{ name: "idturmadisc", id: "idturmadisc" }}
                onChange={this.onSubjectChange}
              >
                {subjects.map((item, index) => (
                  <MenuItem key={index} value={item.idturmadisc}>
                    {item.subject}
                  </MenuItem>
                ))}
              </Select>
            </StyledFormControlFullWdith>

            {dates.length > 0 && (
              <StyledFormControlFullWdith>
                <MuiPickersUtilsProvider
                  utils={MomentUtils}
                  locale="pt-BR"
                  moment={moment}
                >
                  <DatePicker
                    autoOk
                    shouldDisableDate={this.disableDate}
                    format="DD/MM/YYYY"
                    minDate={moment(
                      moment.utc().format("YYYY") + "-01-01"
                    ).utc()}
                    maxDate={moment(
                      moment.utc().format("YYYY") + "-12-31"
                    ).utc()}
                    value={data.date}
                    label="Data"
                    margin="normal"
                    onChange={this.onDateChange}
                  />
                </MuiPickersUtilsProvider>
              </StyledFormControlFullWdith>
            )}

            {subjectTimes.length > 0 && (
              <StyledFormControlFullWdith>
                <List dense id="hours-list">
                  {subjectTimes.map((time) => (
                    <ListItem
                      key={time.idHorarioTurma}
                      button
                      onClick={() => this.onSubjectTimeClick(time)}
                    >
                      <ListItemText
                        primary={`${time.startHour} às ${time.endHour}`}
                      />
                      <ListItemSecondaryAction>
                        <Checkbox
                          color="primary"
                          className="student"
                          onChange={() => this.onSubjectTimeClick(time)}
                          checked={
                            data.idsHorarioTurma.indexOf(
                              time.idHorarioTurma
                            ) !== -1
                          }
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              </StyledFormControlFullWdith>
            )}

            {loading && (
              <StyledFormControlFullWdith>
                <CircularProgress className={classes.progress} />
              </StyledFormControlFullWdith>
            )}

            {students.length > 0 && (
              <StyledFormControlFullWdith>
                {data.idsHorarioTurma.length > 1 && (
                  <div style={{ textAlign: "center" }}>
                    O sistema recupera chamada apenas quando selecionado um
                    único horário!
                  </div>
                )}
                <Typography
                  variant="h6"
                  component="h2"
                  className={classes.title}
                >
                  Marque somente os alunos que faltaram a aula!
                </Typography>
                <List dense id="student-list">
                  {this.state.students.map((student, key) => (
                    <ListItem
                      key={key}
                      button
                      onClick={() => this.onStudentClick(student)}
                    >
                      <ListItemText
                        primary={`${student.ra} - ${student.nome}`}
                      />
                      <ListItemSecondaryAction>
                        <Checkbox
                          color="primary"
                          className="student"
                          onChange={() => this.onStudentClick(student)}
                          checked={data.students.indexOf(student.ra) !== -1}
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              </StyledFormControlFullWdith>
            )}

            {!!error && (
              <StyledFormControlFullWdith>
                <MySnackbar
                  onClose={this.handleClose}
                  open={true}
                  variant="error"
                  className={classes.globalError}
                  message={error}
                />
              </StyledFormControlFullWdith>
            )}

            {success && (
              <StyledFormControlFullWdith>
                <MySnackbar
                  onClose={this.handleClose}
                  open={true}
                  variant="success"
                  className={classes.success}
                  message="Salvo com Sucesso"
                />
              </StyledFormControlFullWdith>
            )}

            <StyledFormControl>
              <Button
                className={classes.submit}
                type="submit"
                color="primary"
                variant="contained"
                disabled={this.state.loading || students.length === 0}
              >
                SALVAR
              </Button>
            </StyledFormControl>
          </form>
        </Paper>
      </Fragment>
    );
  }
}

RollCall.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  incidents: state.incident.incidents,
});

export default connect(mapStateToProps)(withStyles(styles)(RollCall));
