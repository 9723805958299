import React from 'react'
import { Download, BackButton, Buttons } from '../../styles';
import Result from '../Result';

export default function SimpleReport({ codturma, teacher, data, onBackPress }) {

  return (
    <>
      <Buttons>
        <Download
          id="report3-table-xls-button"
          className="download-table-xls-button"
          table="table-to-xls-report3"
          filename="relatorio"
          sheet="tablexls"
          buttonText="Download" />

        <BackButton onClick={onBackPress}>Voltar</BackButton>
      </Buttons>
      <Result key={teacher} teacher={teacher} codturma={codturma} data={data} />
    </>
  )
}
