import { combineReducers } from 'redux';

import user from './user';
import incident from './incident';
import rollcall from './rollcall';

export default combineReducers({
  user,
  incident,
  rollcall
});
