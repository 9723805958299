import styled from 'styled-components';

export const CellCodturma = styled.td`
  color: red;
  font-size: 18px;
  padding: 10px 20px !important;
`;

export const CellTimes = styled.td`
  width: 150px;
`;

export const StatusOK = styled.div`
  color: #28a745;

  &:not(last-child) {
    margin-bottom: 5px;
  }
`;

export const StatusNOK = styled.div`
  color: #DE3B3B;

  &:not(last-child) {
    margin-bottom: 5px;
  }
`;
