import axios from 'axios';
import qs from 'qs';

const api = axios.create({
  baseURL: process.env.REACT_APP_CENSACHAMADOS_HOST || 'http://localhost/app_dev.php/api/'
});

export default {
  user: {
    login: (username, password) =>
      api.post('/login', { username, password }).then(res => res.data),
    fetchCurrentUser: () => api.get('/current-user').then(res => res.data)
  },
  incident: {
    fetchTeacherClasses: () =>
      api.get('/teacher-classes').then(res => res.data),
    fetchCausesAndRequests: () =>
      api.get('/causes-and-requests').then(res => res.data),
    fetchCauses: () => api.get('/causes').then(res => res.data),
    fetchStudents: (name, idturmadisc) =>
      api
        .get('/students?' + qs.stringify({ name, idturmadisc }))
        .then(res => res.data),
    fetchStudentsTurmadisc: idturmadisc =>
      api.get(`/students/${idturmadisc}`).then(res => res.data),
    newIncident: data => api.post('/incident', { data }).then(res => res.data),
    fetchAll: () => api.get('/incidents').then(res => res.data),
    fetchWithFilter: (
      startDate,
      endDate,
      cause,
      class_,
      idturmadisc,
      student
    ) =>
      api
        .get(
          '/incidents?' +
          qs.stringify({
            startDate,
            endDate,
            cause,
            class_,
            idturmadisc,
            student
          })
        )
        .then(res => res.data),
    fetchIncidentsStudent: search =>
      api.get(`/incidents/student/${search}`).then(res => res.data),
    fetchIncidentsClass: search =>
      api.get(`/incidents/class/${search}`).then(res => res.data),
    delete: id => api.delete(`/incident/${id}`).then(res => res.data)
  },
  rollcalls: {
    fetchLessonPlans: idturmadisc =>
      api.get(`rollcalls/lesson-plans/${idturmadisc}`).then(res => res.data),
    store: data => api.post('rollcalls', data).then(res => res.data)
  },
  teacherIncident: {
    fetchCauses: () => api.get('teacher-causes').then(res => res.data),
    fetchTeachers: () => api.get('teachers').then(res => res.data),
    fetchWithFilter: filter =>
      api
        .get('teacher-incidents?' + qs.stringify(filter))
        .then(res => res.data),
    store: data => api.post('teacher-incidents', data).then(res => res.data),
    delete: id => api.delete(`/teacher-incidents/${id}`).then(res => res.data)
  },
  system: {
    currentPerlet: () => api.get('/current-perlet').then(res => res.data)
  },
  axios: api
};
