import { INCIDENT_SEARCHED } from '../types';

const INITIAL_STATE = {
  endDate: '2019-12-31',
  startDate: '2019-01-01',
  incidents: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case INCIDENT_SEARCHED:
      return {
        incidents: action.payload.incidents,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate
      };
    default:
      return state;
  }
};
