import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import {
  Typography,
  Card,
  withStyles,
  CardContent,
  CardActions,
  Avatar,
  IconButton,
  Zoom,
} from "@material-ui/core";
import moment from "moment";
import api from "../../api";
import { Delete } from "@material-ui/icons";

const style = (theme) => ({
  card: {
    width: 350,
    margin: theme.spacing(2),
  },
  cardContent: {
    minheight: 300,
  },
  avatar: {
    margin: "0 auto",
    width: 100,
    height: 100,
    marginBottom: theme.spacing(2),
  },
  delete: {
    justifyContent: "center",
  },
});

const host = process.env.REACT_APP_CENSACHAMADOS_HOST;

export class IncidentListItem extends PureComponent {
  state = {
    collapse: false,
  };

  onDeleteClick = () => {
    const answer = window.confirm("Deseja realmente excluir este registro?");
    if (answer) {
      api.incident
        .delete(this.props.incident.idincident)
        .then(() => {
          this.setState({
            collapse: true,
          });
        })
        .catch((err) => {
          if (!!err.response.data.message) {
            alert(err.response.data.message);
          } else {
            alert("Erro ao excluir. Tente novamente, por favor.");
          }
        });
    }
  };

  render() {
    const { incident, classes } = this.props;
    return (
      <Zoom in={!this.state.collapse} timeout={1000}>
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <Avatar
              alt={incident.student}
              src={host + incident.picture}
              className={classes.avatar}
            />
            <Typography variant="h6" component="h3" noWrap>
              {incident.student}
            </Typography>
            <Typography>{incident.user}</Typography>
            <Typography>{incident.subject}</Typography>
            <Typography>{incident.request}</Typography>

            <Typography>
              {moment(incident.date).format("DD/MM/YYYY")}
            </Typography>
            <Typography>{incident.class}</Typography>
            <Typography>{incident.absence} falta(s)</Typography>
            <Typography>{incident.cause}</Typography>

            <Typography>{incident.description}</Typography>
          </CardContent>
          <CardActions className={classes.delete}>
            <IconButton
              className={classes.deleteButton}
              aria-label="Excluir"
              onClick={() => this.onDeleteClick()}
            >
              <Delete className={classes.deleteIcon} />
            </IconButton>
          </CardActions>
        </Card>
      </Zoom>
    );
  }
}

IncidentListItem.propTypes = {
  incident: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(style)(IncidentListItem);
