import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Redirect, Switch } from 'react-router-dom';
import Loader from 'react-loader';
import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from 'styled-components';
import LoginPage from './components/pages/LoginPage';
import NewIncidentPage from './components/pages/NewIncidentPage';
import IncidentsFilter from './components/pages/IncidentsFilter';
import UserRoute from './components/routes/UserRoute';
import GuestRoute from './components/routes/GuestRoute';
import CoordinatorRoute from './components/routes/CoordinatorRoute';
import { fetchCurrentUser } from './actions/user';
import NotFoundPage from './components/pages/NotFoundPage';
import Report from './components/pages/Report';
import './config/ReactotronConfig';
import RollCall from './components/pages/RollCall';
import NewTeacherIncident from './components/pages/NewTeacherIncident';
import TeacherIncidentsFilter from './components/pages/TeacherIncidentsFilter';
import TeacherReport from './components/pages/TeacherReport';
import Reports from './components/pages/Reports';
import GlobalStyles from './css/global'
import ReportStudentCauseFoul from './components/pages/Reports/ReportStudentCauseFoul';
import ReportFoulByTeacher from './components/pages/Reports/ReportFoulByTeacher';
import ReportIncidentByTeacher from './components/pages/Reports/ReportIncidentByTeacher';
import ReportRollcallByClass from './components/pages/Reports/ReportRollcallByClass';

const theme = createMuiTheme({
  typography: {
    useNextVariants: true
  },
  palette: {
    primary: { main: '#5d9acf' },
    secondary: { main: '#ab40ef' }
  }
});

export class App extends Component {
  componentDidMount() {
    // if (this.props.isAuthenticated && !this.props.loaded) this.props.fetchCurrentUser();
  }

  render() {
    const { location, loaded } = this.props;
    return (
      <MuiThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <>
            <Loader loaded={loaded} color="#fff">
              <GlobalStyles />
              <CssBaseline />

              <Switch>
                <Route
                  location={location}
                  path="/"
                  exact
                  component={() => <Redirect to="/novo-chamado" />}
                />

                <UserRoute
                  location={location}
                  path="/novo-chamado"
                  exact
                  component={NewIncidentPage}
                />

                <UserRoute
                  location={location}
                  path="/novo-chamado-professor"
                  exact
                  component={NewTeacherIncident}
                />

                <UserRoute
                  location={location}
                  path="/chamados"
                  exact
                  component={IncidentsFilter}
                />

                <UserRoute
                  location={location}
                  path="/chamados-professor"
                  exact
                  component={TeacherIncidentsFilter}
                />

                <UserRoute
                  location={location}
                  path="/relatorio"
                  exact
                  component={Report}
                />

                <UserRoute
                  location={location}
                  path="/relatorio-professores"
                  exact
                  component={TeacherReport}
                />

                <UserRoute
                  location={location}
                  path="/chamada"
                  exact
                  component={RollCall}
                />

                <CoordinatorRoute
                  location={location}
                  path="/relatorios"
                  exact
                  component={Reports}
                />

                <CoordinatorRoute
                  location={location}
                  path="/relatorios/aluno-motivo-falta"
                  exact
                  component={ReportStudentCauseFoul}
                />

                <CoordinatorRoute
                  location={location}
                  path="/relatorios/faltas-por-professor"
                  exact
                  component={ReportFoulByTeacher}
                />

                <CoordinatorRoute
                  location={location}
                  path="/relatorios/chamados-por-professor"
                  exact
                  component={ReportIncidentByTeacher}
                />

                <CoordinatorRoute
                  location={location}
                  path="/relatorios/chamada-por-turma"
                  exact
                  component={ReportRollcallByClass}
                />

                <GuestRoute
                  location={location}
                  path="/login"
                  exact
                  component={LoginPage}
                />
                <GuestRoute
                  location={location}
                  path="/login"
                  exact
                  component={LoginPage}
                />
                <Route path="*" component={NotFoundPage} />
              </Switch>
            </Loader>
          </>
        </ThemeProvider>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: !!state.user.name,
  loaded: state.user.loaded
});

App.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired
};

export default connect(
  mapStateToProps,
  { fetchCurrentUser }
)(App);
