import React, { useState, useCallback } from 'react'
import TopNavigation from '../../../navigation/TopNavigation'
import { Container, Paper, FormControl, Button, Title } from '../styles'
import {
  InputLabel,
} from '@material-ui/core';
import AsyncSelect from 'react-select/async';
import api from '../../../../api';
import Result from './Result';


export default function ReportStudentCauseFoul({ history }) {

  const [typingTimeout, setTypingTimeout] = useState(0);
  const [loading, setLoading] = useState(false)
  const [student, setStudent] = useState('');
  const [result, setResult] = useState(null)

  const memorizedOnBackPress = useCallback(() => {
    setResult(null)
    setStudent(null)
  }, []);

  function studentsOptions(inputValue, callback) {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    setTypingTimeout(
      setTimeout(async () => {
        const { data: students } = await api.axios.get(`/students?name=${inputValue}`);
        callback(students);
      },
        1500)
    );
    return null;
  };

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true)
    try {
      const { data } = await api.axios.get(`reports/student-cause-foul/${student.value}`)
      setResult(data)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Container>
      <TopNavigation history={history} />
      <Paper>
        <Title>Relatório Aluno Motivo/Falta</Title>
        {result ?
          <Result student={student} data={result} onBackPress={memorizedOnBackPress} />
          :

          <form onSubmit={handleSubmit}>
            <FormControl>
              <InputLabel htmlFor="student">Aluno</InputLabel>
              <AsyncSelect
                inputProps={{
                  id: 'student',
                  name: 'student'
                }}
                onChange={input => setStudent(input)}
                isClearable
                cacheOptions
                defaultOptions
                loadOptions={studentsOptions}
                placeholder="Aluno"
                value={student}
              />
            </FormControl>
            <FormControl>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={!student || loading}
              >
                GERAR
              </Button>
            </FormControl>
          </form>
        }
      </Paper>
    </Container>
  )
}
