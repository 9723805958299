import React, { Component, Fragment } from 'react';

import { Container, Text, Signature, Item } from './styles';
import Loader from 'react-loader';

import moment from 'moment';

const logo = require('../../../images/censa_chamado_logo.png');

class TeacherReport extends Component {
  state = {
    dates: [],
    startDate: null,
    endDate: null,
    loading: true
  };

  componentDidMount() {
    const incidents = JSON.parse(
      localStorage.getItem('@Censachamados:teacherIncidents')
    );
    let dates = [];
    incidents.map(incident =>
      dates.find(
        date => date.date === moment(incident.date).format('DD/MM/YYYY')
      )
        ? null
        : dates.push({
          date: moment(incident.date).format('DD/MM/YYYY'),
          causes: []
        })
    );

    dates.map(date => {
      let incidentsDate = [];
      incidents.map(incident => {
        if (date.date === moment(incident.date).format('DD/MM/YYYY')) {
          incidentsDate.push(incident);
          if (
            date.causes.find(cause => cause.cause === incident.cause) ===
            undefined
          ) {
            date.causes.push({ cause: incident.cause, teachers: [] });
          }
        }
        return true;
      });

      //filtra incidents

      date.causes.map(cause => {
        incidentsDate.map(incident => {
          if (cause.cause === incident.cause) {
            cause.teachers.push(incident.teacher);
          }
          return true;
        });
        return true;
      });

      return true;
    });

    const startDate = localStorage.getItem('@Censachamados:teacherStartDate');
    const endDate = localStorage.getItem('@Censachamados:teacherEndDate');

    this.setState({ dates, startDate, endDate, loading: false });
  }

  render() {
    const { dates, startDate, endDate, loading } = this.state;
    return (
      <Loader loaded={!loading} color="#fff">
        <Container>
          <img src={logo} alt="Logo Censa" title="Logo Censa" />
          <Text>
            Relação de ocorrências no periodo de{' '}
            {moment(startDate).format('DD/MM/YYYY')} à{' '}
            {moment(endDate).format('DD/MM/YYYY')}.
          </Text>
          {dates.map(date => (
            <Fragment key={date.date}>
              <Item bold>{date.date}</Item>

              {date.causes.map(cause => (
                <Fragment key={cause.cause}>
                  <Item bold margin={1}>
                    {cause.cause}
                  </Item>
                  {cause.teachers.map(teacher => (
                    <Item key={teacher} margin={2}>
                      {teacher}
                    </Item>
                  ))}
                </Fragment>
              ))}
            </Fragment>
          ))}
          <Signature>
            Campos dos Goytacazes, {moment.utc().format('LL')}
          </Signature>
        </Container>
      </Loader>
    );
  }
}

export default TeacherReport;
