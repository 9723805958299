import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

const GuestRoute = ({ isAuthenticaded, component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      !isAuthenticaded ? (
        <Component {...props} />
      ) : (
        <Redirect to="/novo-chamado" />
      )
    }
  />
);

GuestRoute.propTypes = {
  component: PropTypes.object.isRequired,
  isAuthenticaded: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  isAuthenticaded: !!state.user.name
});

export default connect(mapStateToProps)(GuestRoute);
