import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import {
  Typography,
  Card,
  withStyles,
  CardContent,
  CardActions,
  IconButton,
  Zoom,
} from "@material-ui/core";
import moment from "moment";
import api from "../../../../api";
import { Delete } from "@material-ui/icons";
import styles from "./styles";

export class IncidentListItem extends PureComponent {
  state = {
    collapse: false,
  };

  onDeleteClick = () => {
    const { incident } = this.props;
    const answer = window.confirm("Deseja realmente excluir este registro?");
    if (answer) {
      api.teacherIncident
        .delete(incident.idteacher_incident)
        .then(() => {
          this.setState({
            collapse: true,
          });
        })
        .catch((err) => {
          if (!!err.response.data.message) {
            alert(err.response.data.message);
          } else {
            alert("Erro ao excluir. Tente novamente, por favor.");
          }
        });
    }
  };

  render() {
    const { incident, classes } = this.props;
    return (
      <Zoom in={!this.state.collapse} timeout={1000}>
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <Typography variant="h6" component="h3" noWrap>
              {incident.teacher}
            </Typography>

            <Typography>
              <strong className={classes.title}>Data: </strong>
              {moment(incident.date).format("DD/MM/YYYY")}
            </Typography>
            <Typography>
              <strong className={classes.title}>Motivo:</strong>{" "}
              {incident.cause}
            </Typography>

            <Typography>
              <strong className={classes.title}>Descrição: </strong>{" "}
              {incident.description}
            </Typography>
          </CardContent>
          <CardActions className={classes.delete}>
            <IconButton
              className={classes.deleteButton}
              aria-label="Excluir"
              onClick={() => this.onDeleteClick()}
            >
              <Delete className={classes.deleteIcon} />
            </IconButton>
          </CardActions>
        </Card>
      </Zoom>
    );
  }
}

IncidentListItem.propTypes = {
  incident: PropTypes.shape({
    teacher: PropTypes.string,
    date: PropTypes.string,
    cause: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(IncidentListItem);
