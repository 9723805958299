import styled from "styled-components";

export const Container = styled.div`
  size: auto;
  max-width: 210mm;
  padding: 15mm 10mm 10mm 10mm;
  background: white;
  text-align: center;
  height: auto;
  margin: 10mm auto;
  border-radius: 3px;

  img {
    margin-bottom: 30px;
  }
`;

export const Text = styled.p`
  padding: 10px;
  text-align: left;
`;

export const Signature = styled.div`
  margin-top: 80px;
  border-top: solid 1px;
`;

export const Item = styled.div`
  padding: 0;
  text-align: left;
  font-weight: ${(props) => (props.bold ? "bold" : "normal")};
  margin-left: ${(props) => (props.margin ? props.margin * 50 : 0)}px;
`;

export const ContextContainer = styled.div`
  margin: 15px 0 15px 0;
`;
