import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';


const CoordinatorRoute = ({ isCoordinator, component: Component, ...rest }) => (
  <Route {...rest} render={props => isCoordinator ? <Component {...props} /> : <Redirect to='/novo-chamado' />} />
);

const mapStateToProps = state => ({
  isCoordinator: !!state.user.coordinator
});

export default connect(mapStateToProps)(CoordinatorRoute)
