import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import {
  Typography,
  Card,
  withStyles,
  CardContent,
  CardActions,
  Avatar,
  IconButton,
  Zoom,
} from "@material-ui/core";
import moment from "moment";
import { Delete } from "@material-ui/icons";
import api from "../../api";
import { FlexDiv } from "../../css/styled";

const style = (theme) => ({
  card: {
    width: 350,
    margin: theme.spacing(2),
  },
  cardContent: {
    minheight: 300,
  },
  avatar: {
    width: 60,
    height: 60,
    cursor: "pointer",
    margin: theme.spacing(1),
  },
  delete: {
    justifyContent: "center",
  },
});

const host = process.env.REACT_APP_CENSACHAMADOS_HOST;

export class IncidentClassListItem extends PureComponent {
  state = {
    collapse: false,
  };

  onDeleteClick = () => {
    const answer = window.confirm("Deseja realmente excluir este registro?");
    if (answer) {
      api.incident
        .delete(this.props.incident.idincident)
        .then(() => {
          this.setState({
            collapse: true,
          });
        })
        .catch((err) => {
          if (err.response.data.message) {
            alert(err.response.data.message);
          } else {
            alert("Erro ao excluir. Tente novamente, por favor.");
          }
        });
    }
  };

  studentTitle = (student, absence) => `${student} - ${absence} falta(s)`;

  sort = (student1, student2) => {
    if (student1 < student2) return -1;
    if (student1 > student2) return 1;

    return 0;
  };

  render() {
    const { incident, classes } = this.props;
    const students = incident.students.split(";");
    const absences = incident.absences.split(";");
    const pictures = incident.pictures.split(";");

    return (
      <Zoom in={!this.state.collapse} timeout={1000}>
        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            <Typography>{incident.user}</Typography>
            <Typography>{incident.subject}</Typography>
            <Typography>{incident.request}</Typography>
            <Typography>
              {moment(incident.date).format("DD/MM/YYYY")}
            </Typography>
            <Typography>{incident.class}</Typography>
            <Typography>{incident.cause}</Typography>

            <Typography>{incident.description}</Typography>
            <FlexDiv>
              {pictures.map((value, key) => (
                <Avatar
                  key={key}
                  className={classes.avatar}
                  src={host + value}
                  alt="teste"
                  title={this.studentTitle(students[key], absences[key])}
                />
              ))}
            </FlexDiv>
          </CardContent>
          <CardActions className={classes.delete}>
            <IconButton
              className={classes.deleteButton}
              aria-label="Excluir"
              onClick={() => this.onDeleteClick()}
            >
              <Delete className={classes.deleteIcon} />
            </IconButton>
          </CardActions>
        </Card>
      </Zoom>
    );
  }
}

IncidentClassListItem.propTypes = {
  incident: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(style)(IncidentClassListItem);
