import React, { Fragment } from "react";
import PropTypes from "prop-types";

import { withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import {
  Avatar,
  Menu,
  MenuItem,
  SwipeableDrawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  InputBase,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { Class } from "@material-ui/icons";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { PersonAdd, ViewList, AssignmentInd } from "@material-ui/icons";
import { logout } from "../../actions/auth";
import styles from "./styles";

const host = process.env.REACT_APP_CENSACHAMADOS_HOST;

class TopNavigation extends React.Component {
  state = {
    receiveSearch: true,
    anchorEl: null,
    drawerOpen: false,
    search: "",
  };

  handleMenu = (e) => this.setState({ anchorEl: e.currentTarget });

  handleClose = () => this.setState({ anchorEl: null });

  toggleDrawer = (open) => this.setState({ drawerOpen: open });

  openDrawer = () => this.setState({ drawerOpen: true });

  closeDrawer = () => this.setState({ drawerOpen: false });

  render() {
    const { classes, name, picture, coordinator } = this.props;
    const { anchorEl, drawerOpen } = this.state;
    const open = !!anchorEl;
    return (
      <div className={classes.root}>
        <SwipeableDrawer
          open={drawerOpen}
          onClose={this.closeDrawer}
          onOpen={this.openDrawer}
        >
          <div tabIndex={0} role="button" onClick={this.closeDrawer}>
            <div className={classes.menuList}>
              <List>
                <ListItem button component={Link} to="/novo-chamado">
                  <ListItemIcon>
                    <PersonAdd />
                  </ListItemIcon>
                  <ListItemText primary="Novo" />
                </ListItem>
                <ListItem button component={Link} to="/chamados">
                  <ListItemIcon>
                    <ViewList />
                  </ListItemIcon>
                  <ListItemText primary="Chamados" />
                </ListItem>
                <ListItem button component={Link} to="/chamada">
                  <ListItemIcon>
                    <AssignmentInd />
                  </ListItemIcon>
                  <ListItemText primary="Chamada" />
                </ListItem>

                {coordinator && (
                  <Fragment>
                    <ListItem
                      button
                      component={Link}
                      to="/novo-chamado-professor"
                    >
                      <ListItemIcon>
                        <PersonAdd />
                      </ListItemIcon>
                      <ListItemText primary="Novo Chamado Professor" />
                    </ListItem>

                    <ListItem button component={Link} to="/chamados-professor">
                      <ListItemIcon>
                        <Class />
                      </ListItemIcon>
                      <ListItemText primary="Chamados Professor" />
                    </ListItem>
                    <ListItem button component={Link} to="/relatorios">
                      <ListItemIcon>
                        <Class />
                      </ListItemIcon>
                      <ListItemText primary="Relatórios" />
                    </ListItem>
                    <p style={{ textAlign: "end", marginRight: 20 }}>
                      Versão: {process.env.REACT_APP_VERSION}
                    </p>
                  </Fragment>
                )}
              </List>
            </div>
          </div>
        </SwipeableDrawer>

        <AppBar position="static">
          <Toolbar>
            <IconButton
              className={classes.menuButton}
              color="inherit"
              aria-label="Menu"
              onClick={this.openDrawer}
            >
              <MenuIcon />
            </IconButton>

            <img
              className={classes.title}
              src={require("../../images/censa_chamado_logo_horizontal.png")}
              alt="Logo Censa"
              title="Logo Censa"
            />

            {1 === 0 && (
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  placeholder="Pesquisar"
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                  }}
                  value={this.state.search}
                  onChange={this.onSearchChange}
                  onKeyDown={this.onSearchKeyDown}
                />
              </div>
            )}
            <div className={classes.grow} />

            <div>
              <h4 style={{ padding: '10px' }}>Olá, {name}</h4>
            </div>

            <div>
              <Avatar
                alt={name}
                src={host + picture}
                onClick={this.handleMenu}
                className={classes.avatar}
              />
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={open}
                onClose={this.handleClose}
              >
                <MenuItem onClick={this.props.logout}>Logout</MenuItem>
              </Menu>
            </div>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

TopNavigation.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  picture: PropTypes.string.isRequired,
  logout: PropTypes.func.isRequired,
  coordinator: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  name: state.user.name,
  coordinator: state.user.coordinator,
  picture: state.user.picture,
});

export default connect(mapStateToProps, { logout })(
  withStyles(styles)(TopNavigation)
);
