import React, { useState, useEffect, useCallback } from 'react'
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import qs from 'qs';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import 'moment/locale/pt-br';
import { Select, MenuItem, InputLabel } from '@material-ui/core';

import { Container, Paper, FormControl, Button, Title } from '../styles'
import TopNavigation from '../../../navigation/TopNavigation'
import api from '../../../../api';
import Result from './Result';

export default function ReportRollcallByClass({ history }) {

  const [startDate, setStartDate] = useState(moment().startOf('month'));
  const [endDate, setEndDate] = useState(moment().endOf('month'));
  const [codturma, setCodturma] = useState('');
  const [classes, setClasses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);

  const memorizedOnBackPress = useCallback(() => {
    setResult(null)
    setCodturma('')
  }, []);

  useEffect(() => {
    async function load() {
      const { data } = await api.axios.get('/reports/classes')
      setClasses(data);
    }

    load();
  }, []);

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);

    try {
      const { data } = await api.axios.get('/reports/rollcall-by-class?' +
        qs.stringify({
          startDate: startDate.format('YYYY-MM-DD'),
          endDate: endDate.format('YYYY-MM-DD'),
          codturma
        }));

      setResult(data);
    } finally {
      setLoading(false)
    }
  }

  return (
    <Container>
      <TopNavigation history={history} />
      <Paper width={result ? 'initial' : '600px'}>
        <Title>Relatório Chamada por Turma</Title>
        {result ?
          <Result codturma={codturma} data={result} onBackPress={memorizedOnBackPress} />
          :
          <form onSubmit={handleSubmit}>
            <FormControl width='46%'>
              <MuiPickersUtilsProvider
                utils={MomentUtils}
                locale="pt-BR"
                moment={moment}
              >
                <DatePicker
                  autoOk
                  format="DD/MM/YYYY"
                  value={startDate}
                  label="Periodo Inicial"
                  margin="normal"
                  onChange={date => setStartDate(date)}
                />
              </MuiPickersUtilsProvider>
            </FormControl>
            <FormControl width='46%'>
              <MuiPickersUtilsProvider
                utils={MomentUtils}
                locale="pt-BR"
                moment={moment}
              >
                <DatePicker
                  autoOk
                  format="DD/MM/YYYY"
                  value={endDate}
                  label="Periodo Final"
                  margin="normal"
                  onChange={date => setEndDate(date)}
                />
              </MuiPickersUtilsProvider>
            </FormControl>
            <FormControl>
              <InputLabel htmlFor="class">Turma</InputLabel>
              <Select
                value={codturma}
                inputProps={{ name: 'class', id: 'class' }}
                onChange={e => setCodturma(e.target.value)}
              >
                {classes.map((item, index) => (
                  <MenuItem key={index} value={item.CODTURMA}>
                    {item.CODTURMA}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={!codturma || loading}
              >
                GERAR
              </Button>
            </FormControl>
          </form>
        }
      </Paper>
    </Container>
  )
}
