import React from 'react'
import moment from 'moment'
import { Download, BackButton, Buttons, Table } from '../../styles';
import { CellCodturma } from './styles';

export default function Result({ codturma, data, onBackPress }) {

  function foulTeacher(student, teacher) {

    const fouls = data.fouls.filter(foul => foul.ra === student.ra && foul.codprof === teacher.codprof)
    const dates = fouls.map(foul => moment(foul.data).format('DD/MM'))
    if (dates.length === 0) {
      return 0;
    }

    return dates.length + " (" + dates.join('-') + ")"
  }

  return (
    <>
      <Buttons>
        <Download
          id="report2-table-xls-button"
          className="download-table-xls-button"
          table="table-to-xls-report2"
          filename="relatorio"
          sheet="tablexls"
          buttonText="Download" />

        <BackButton onClick={onBackPress}>Voltar</BackButton>
      </Buttons>

      <Table cellPadding={0} cellSpacing={0} id='table-to-xls-report2'>
        <tbody>
          <tr>
            <td>&nbsp;</td>
            <CellCodturma colSpan={data.teachers.length + 1}>{codturma}</CellCodturma>
          </tr>
          <tr>
            <td>&nbsp;</td>
            {data.teachers.map(teacher =>
              <td key={teacher.codprof}>
                {teacher.nome}
              </td>
            )}
          </tr>

          {data.students.map(student =>
            <tr key={student.ra}>
              <td>{student.ra} - {student.nome}</td>
              {data.teachers.map(teacher =>
                <td key={teacher.codprof}>
                  {foulTeacher(student, teacher)}
                </td>
              )}
            </tr>
          )}

        </tbody>
      </Table>
    </>
  )
}
