import React, { Component, Fragment } from "react";

import { Container, Text, Signature, Item, ContextContainer } from "./styles";
import Loader from "react-loader";

import moment from "moment";

const logo = require("../../../images/censa_chamado_logo.png");

class Report extends Component {
  state = {
    dates: [],
    startDate: null,
    endDate: null,
    loading: true,
  };

  componentDidMount() {
    const incidents = JSON.parse(
      localStorage.getItem("@Censachamados:incidents")
    );

    let dates = [];
    incidents.map((incident) =>
      dates.find(
        (date) => date.date === moment(incident.date).format("DD/MM/YYYY")
      )
        ? null
        : dates.push({
            date: moment(incident.date).format("DD/MM/YYYY"),
            causes: [],
          })
    );

    console.log(dates);
    console.log(incidents);

    dates.map((date) => {
      let incidentsDate = [];
      incidents.map((incident) => {
        // console.log(date.date, moment(incident.date).format("DD/MM/YYYY"));
        if (date.date === moment(incident.date).format("DD/MM/YYYY")) {
          incidentsDate.push(incident);
          if (
            date.causes.find((cause) => {
              return cause.cause === incident.cause;
            }) === undefined
          ) {
            date.causes.push({ cause: incident.cause, subjects: [] });
          }
        }
        return true;
      });

      //filtra incidents

      date.causes.map((cause) => {
        let incidentsCause = [];
        incidentsDate.map((incident) => {
          if (cause.cause === incident.cause) {
            incidentsCause.push(incident);

            const ras = incident.ras.split(";");
            const names = incident.students.split(";");

            const students = names.map(
              (student, index) => `${ras[index]} - ${student}`
            );

            cause.subjects.push({
              subject: incident.subject,
              context:
                incident.class +
                " - " +
                incident.subject +
                " - " +
                incident.user,
              description: incident.description,
              students: students,
            });
          }
          return true;
        });

        return true;
      });

      return true;
    });

    const startDate = localStorage.getItem("@Censachamados:startDate");
    const endDate = localStorage.getItem("@Censachamados:endDate");

    this.setState({ dates, startDate, endDate, loading: false });
  }

  sort = (student1, student2) => {
    const [, name1] = student1.split(" - ");
    const [, name2] = student2.split(" - ");
    if (name1 < name2) return -1;
    if (name1 > name2) return 1;

    return 0;
  };

  render() {
    const { dates, startDate, endDate } = this.state;
    return (
      <Loader loaded={!this.state.loading} color="#fff">
        <Container>
          <img src={logo} alt="Logo Censa" title="Logo Censa" />
          <Text>
            Relação de ocorrências no periodo de{" "}
            {moment(startDate).format("DD/MM/YYYY")} à{" "}
            {moment(endDate).format("DD/MM/YYYY")}.
          </Text>
          {dates.map((date, index) => (
            <Fragment key={String(index)}>
              <Item bold>{date.date}</Item>

              {date.causes.map((cause, index) => (
                <Fragment key={String(index)}>
                  <Item bold margin={1}>
                    {cause.cause}
                  </Item>
                  {cause.subjects.map((subject, index) => (
                    <ContextContainer key={String(index)}>
                      <Item bold margin={2}>
                        {subject.context}
                      </Item>
                      <Item style={{ marginBottom: 5 }} bold margin={2}>
                        Descrição: {subject.description}
                      </Item>
                      {subject.students
                        .sort(this.sort)
                        .map((student, index) => (
                          <Item key={String(index)} margin={3}>
                            {student}
                          </Item>
                        ))}
                    </ContextContainer>
                  ))}
                </Fragment>
              ))}
            </Fragment>
          ))}
          <Signature>
            Campos dos Goytacazes, {moment.utc().format("LL")}
          </Signature>
        </Container>
      </Loader>
    );
  }
}

export default Report;
