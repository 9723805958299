const styles = theme => ({
  incidents: {
    display: 'flex',
    alignItens: 'center',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  title: {
    textAlign: 'center',
    padding: theme.spacing(2),
    color: '#fff',
    fontWeight: 'bold'
  },
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    width: 'auto',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    marginTop: 20,
    [theme.breakpoints.up(600 + theme.spacing(3 * 2))]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto'
    },
    position: 'relative',
    zIndex: 1
  },
  form: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'baseline'
  }
});

export default styles;
