import styled from 'styled-components';

export const CellStudentName = styled.td`
  color: red;
  font-size: 18px;
  padding: 10px 20px !important;
`;

export const CellTeacherName = styled.td`
  text-align: left;
`;
