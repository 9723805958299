import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`

  button {
    cursor: pointer;
  }

  a {
    text-decoration: none;
  }

  html, body, #root, .loadedContent {
    height: 100%;
  }

`;
