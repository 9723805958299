import api from '../api';
import { INCIDENT_SEARCHED } from '../types';

export const incidentSearched = data => ({
  type: INCIDENT_SEARCHED,
  payload: data
});

export const search = filter => dispatch =>
  api.incident
    .fetchWithFilter(
      filter.startDate.format('YYYY-MM-DD'),
      filter.endDate.format('YYYY-MM-DD'),
      filter.cause,
      filter.class,
      filter.idturmadisc,
      filter.student
    )
    .then(incidents => {
      localStorage.setItem(
        '@Censachamados:incidents',
        JSON.stringify(incidents)
      );
      localStorage.setItem(
        '@Censachamados:startDate',
        filter.startDate.format('YYYY-MM-DD')
      );

      localStorage.setItem(
        '@Censachamados:endDate',
        filter.endDate.format('YYYY-MM-DD')
      );

      dispatch(
        incidentSearched({
          incidents,
          startDate: filter.startDate.format('YYYY-MM-DD'),
          endDate: filter.endDate.format('YYYY-MM-DD')
        })
      );
    });
