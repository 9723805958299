import React from 'react'
import moment from 'moment'
import { Download, BackButton, Buttons, Table } from '../../styles';
import { CellCodturma, StatusOK, StatusNOK, CellTimes } from './styles';

export default function Result({ codturma, data, onBackPress }) {
  const dates = [];

  data.times.map(time => {
    if (dates.indexOf(time.data) === -1) {
      dates.push(time.data)
    }
  })

  function statusRollcall(date, teacher) {
    const times = data.times.filter(time => time.codprof === teacher.codprof && time.data === date)

    const response = times.map((time, index) => {
      for (var x = 0; x < data.rollcalls.length; x++) {
        const rollcall = data.rollcalls[x];
        if (time.idturmadisc === rollcall.turmadisc_id
          && time.idhorarioturma === rollcall.horarioturma_id
          && time.data === rollcall.date
          && time.codprof === rollcall.teacher_id) {
          return <StatusOK key={index}>{time.horainicial + ' às ' + time.horafinal + ' - OK'}</StatusOK>;
        }
      }
      return <StatusNOK key={index}>{time.horainicial + ' às ' + time.horafinal + ' - NOK'}</StatusNOK>
    })

    return response;

  }


  return (
    <>
      <Buttons>
        <Download
          id="report2-table-xls-button"
          className="download-table-xls-button"
          table="table-to-xls-report4"
          filename="relatorio"
          sheet="tablexls"
          buttonText="Download" />

        <BackButton onClick={onBackPress}>Voltar</BackButton>
      </Buttons>

      <Table cellPadding={0} cellSpacing={0} id='table-to-xls-report4'>
        <tbody>
          <tr>
            <td>&nbsp;</td>
            <CellCodturma colSpan={dates.length + 1}>{codturma}</CellCodturma>
          </tr>
          <tr>
            <td>&nbsp;</td>
            {dates.map((date, index) =>
              <td key={index}>
                {moment(date).format('DD/MM')}
              </td>
            )}

          </tr>
          {data.teachers.map(teacher =>
            <tr key={teacher.codprof}>
              <td>
                {teacher.nome}
              </td>
              {dates.map((date, index) =>
                <CellTimes key={index}>
                  {statusRollcall(date, teacher)}
                </CellTimes>
              )}
            </tr>
          )}
          <tr>
            <td colSpan={dates.length + 2}>
              Legenda: <StatusOK>OK - Professor realizou chamada</StatusOK> <StatusNOK>NOK - Professor não realizou chamada</StatusNOK>
            </td>
          </tr>

        </tbody>
      </Table>
    </>
  )
}
