import React, { Component, Fragment } from "react";
import TopNavigation from "../navigation/TopNavigation";
import PropTypes from "prop-types";
import api from "../../api";
import {
  Select,
  MenuItem,
  InputLabel,
  withStyles,
  Checkbox,
  FormControlLabel,
  TextField,
  Button,
  Paper,
  Typography,
  Radio,
  FormGroup,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from "@material-ui/core";
import AsyncSelect from "react-select/async";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, DatePicker } from "material-ui-pickers";
import InlineError from "../messages/InlineError";
import { MySnackbar } from "../messages/MySnackBar";
import {
  StyledFormControl,
  StyledFormControlFullWdith,
  StyledFormControlBlack,
} from "../../css/styled";
import moment from "moment";
import "moment/locale/pt-br";

moment.locale("pt-br");

const styles = (theme) => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    width: "auto",
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    marginTop: 20,
    [theme.breakpoints.up(600 + theme.spacing(3 * 2))]: {
      width: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },

  form: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "baseline",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
    width: "40%",
    flexGrow: 1,
  },
  fullWidth: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: "80%",
    flexGrow: 1,
  },
  globalError: {
    marginTop: 20,
  },
  success: {
    marginTop: 20,
  },
  submit: {
    width: 250,
    margin: "0 auto",
  },
});

export class NewIncidentPage extends Component {
  state = {
    data: {
      date: moment.utc(),
      request: 2,
      class: "",
      idturmadisc: "",
      cause: "",
      description: "",
      extraClass: false,
      students: [],
      type: "group",
    },

    errors: {},
    requests: [],
    classes: [],
    turmadiscs: [],
    subjects: [],
    causes: [],
    students: [],
    loading: false,
    success: false,
  };

  componentDidMount = () => {
    api.incident
      .fetchCausesAndRequests()
      .then((resp) =>
        this.setState({ causes: resp.causes, requests: resp.requests })
      );
    api.incident.fetchTeacherClasses().then((turmadiscs) => {
      this.setState({ turmadiscs });
      const classes = turmadiscs.filter((elem, index, self) => {
        for (let x = 0; x < self.length; x++) {
          if (elem.class === self[x].class) {
            return self.indexOf(elem) === x;
          }
        }
        return false;
      });
      this.setState({ classes });
    });
  };

  onChange = (e) =>
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });

  onClassChange = (e) => {
    const subjects = this.state.turmadiscs.filter(
      (elem, i, array) => elem.class === e.target.value
    );
    this.setState({
      subjects,
      students: [],
      data: { ...this.state.data, idturmadisc: "", class: e.target.value },
    });
  };

  onExtraClassChange = (e) =>
    this.setState({
      data: { ...this.state.data, extraClass: e.target.checked },
    });

  onDateChange = (date) =>
    this.setState({ data: { ...this.state.data, date } });

  onStudentListClick = (student) => {
    let { students } = this.state.data;
    const currentIndex = students.indexOf(student.ra);
    if (currentIndex === -1) {
      students = [...students, student.ra];
    } else {
      students = students.filter((ra) => ra !== student.ra);
    }
    this.setState({ data: { ...this.state.data, students } });
  };

  onStudentChange = (data) => {
    const student = data ? data.value : "";
    this.setState({
      data: { ...this.state.data, students: [student] },
    });
  };

  onIncidentTypeChange = (e) => {
    this.setState({
      data: {
        ...this.state.data,
        students: [],
        extraClass: false,
        type: e.target.value,
      },
    });
  };

  onSubjectChange = (e) => {
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });

    if (this.state.data.type === "group") {
      api.incident.fetchStudentsTurmadisc(e.target.value).then((students) => {
        console.log(students);
        this.setState({ students });
      });
    }
  };

  studentsOptions = (inputValue) =>
    api.incident.fetchStudents(inputValue, this.state.data.idturmadisc);

  validate = (data) => {
    const errors = {};
    if (!data.request) errors.request = "Deve ser preenchido";
    if (!data.cause) errors.cause = "Deve ser preenchido";
    if (!data.description) errors.description = "Deve ser preenchido";
    if (!data.extraClass && !data.class) errors.class = "Deve ser preenchido";
    if (!data.extraClass && !data.idturmadisc)
      errors.idturmadisc = "Deve ser preenchido";
    if (data.students.length === 0) errors.student = "Deve ser preenchido";
    return errors;
  };

  onSubmit = (e) => {
    e.preventDefault();
    const errors = this.validate(this.state.data);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.setState({ loading: true });

      api.incident
        .newIncident(this.state.data)
        .then(() => {
          this.setState({ success: true });
          setTimeout(function () {
            window.location.reload();
          }, 2000);
        })
        .catch(() => {
          errors.global = "Erro ao gravar. Tente novamente, por favor.";
          this.setState({ errors, loading: false });
        });
    }
  };

  render() {
    const { classes } = this.props;
    const { data, errors } = this.state;
    const { type } = this.state.data;
    const formStudent = type === "individual" && Boolean(data.idturmadisc);
    const fromStudentList = type === "group" && Boolean(data.idturmadisc);
    return (
      <Fragment>
        <TopNavigation history={this.props.history} />
        <Paper className={classes.root} elevation={1}>
          <Typography variant="h5" component="h3">
            Novo Chamado
          </Typography>

          <form className={classes.form} onSubmit={this.onSubmit}>
            <StyledFormControlFullWdith>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Radio
                      color="primary"
                      checked={"individual" === type}
                      name="incident-type"
                      onChange={this.onIncidentTypeChange}
                      value="individual"
                      aria-label="Individual"
                    />
                  }
                  label="Individual"
                />

                <FormControlLabel
                  control={
                    <Radio
                      color="primary"
                      checked={"group" === type}
                      name="incident-type"
                      onChange={this.onIncidentTypeChange}
                      value="group"
                      aria-label="Turma"
                    />
                  }
                  label="Turma"
                />
              </FormGroup>
            </StyledFormControlFullWdith>

            <StyledFormControl>
              <MuiPickersUtilsProvider
                utils={MomentUtils}
                locale="pt-br"
                moment={moment}
              >
                <DatePicker
                  autoOk
                  format={"DD/MM/YYYY"}
                  value={data.date}
                  label="Data"
                  margin="normal"
                  onChange={this.onDateChange}
                />
              </MuiPickersUtilsProvider>
            </StyledFormControl>

            <StyledFormControl>
              <InputLabel>Solicitação</InputLabel>
              <Select
                value={data.request}
                inputProps={{
                  name: "request",
                  id: "request",
                }}
                onChange={this.onChange}
              >
                {this.state.requests.map((item, index) => (
                  <MenuItem key={index} value={item.idrequest}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
              {errors.request && <InlineError text={errors.request} />}
            </StyledFormControl>

            <StyledFormControl>
              <InputLabel>Motivo</InputLabel>
              <Select
                value={data.cause}
                inputProps={{
                  name: "cause",
                  id: "cause",
                }}
                onChange={this.onChange}
              >
                {this.state.causes.map((item, index) => (
                  <MenuItem key={index} value={item.idcause}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
              {errors.cause && <InlineError text={errors.cause} />}
            </StyledFormControl>

            <StyledFormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={data.extraClass}
                    disabled={type === "group"}
                    onChange={this.onExtraClassChange}
                    color="primary"
                    inputProps={{ "aria-label": "Extra classe?" }}
                  />
                }
                label="Extra classe?"
              />
            </StyledFormControl>

            <StyledFormControl>
              <InputLabel htmlFor="class">Turma</InputLabel>
              <Select
                value={data.class}
                inputProps={{ name: "class", id: "class" }}
                onChange={this.onClassChange}
              >
                {this.state.classes.map((item, index) => (
                  <MenuItem key={index} value={item.class}>
                    {item.class}
                  </MenuItem>
                ))}
              </Select>
              {errors.class && <InlineError text={errors.class} />}
            </StyledFormControl>

            <StyledFormControl>
              <InputLabel htmlFor="idturmadisc">Disciplina</InputLabel>
              <Select
                value={data.idturmadisc}
                inputProps={{ name: "idturmadisc", id: "idturmadisc" }}
                onChange={this.onSubjectChange}
              >
                {this.state.subjects.map((item, index) => (
                  <MenuItem key={index} value={item.idturmadisc}>
                    {item.subject}
                  </MenuItem>
                ))}
              </Select>
              {errors.idturmadisc && <InlineError text={errors.idturmadisc} />}
            </StyledFormControl>

            {formStudent && (
              <StyledFormControlBlack>
                <InputLabel htmlFor="student">Aluno</InputLabel>
                <AsyncSelect
                  inputProps={{
                    id: "student",
                    name: "student",
                  }}
                  onChange={this.onStudentChange}
                  isClearable
                  cacheOptions
                  defaultOptions
                  loadOptions={this.studentsOptions}
                  placeholder="Aluno"
                />
                {errors.student && <InlineError text={errors.student} />}
              </StyledFormControlBlack>
            )}

            {fromStudentList && (
              <StyledFormControlFullWdith>
                <List dense id="student-list">
                  {this.state.students.map((student, key) => (
                    <ListItem
                      key={key}
                      button
                      onClick={() => this.onStudentListClick(student)}
                    >
                      <ListItemText
                        primary={`${student.ra} - ${student.nome}`}
                      />
                      <ListItemSecondaryAction>
                        <Checkbox
                          color="primary"
                          className="student"
                          onChange={() => this.onStudentListClick(student)}
                          checked={data.students.indexOf(student.ra) !== -1}
                        />
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
                {errors.student && <InlineError text={errors.student} />}
              </StyledFormControlFullWdith>
            )}

            <StyledFormControlFullWdith>
              <TextField
                value={data.description}
                id="description"
                name="description"
                label="Descrição"
                multiline
                onChange={this.onChange}
              />
              {errors.description && <InlineError text={errors.description} />}
            </StyledFormControlFullWdith>

            <StyledFormControl>
              <Button
                className={classes.submit}
                type="submit"
                color="primary"
                variant="contained"
                disabled={this.state.loading}
              >
                SALVAR
              </Button>
              {errors.global && (
                <MySnackbar
                  onClose={this.handleClose}
                  open={!!errors.global}
                  variant="error"
                  className={classes.globalError}
                  message={errors.global}
                />
              )}
              {this.state.success && (
                <MySnackbar
                  onClose={this.handleClose}
                  open={true}
                  variant="success"
                  className={classes.success}
                  message="Salvo com Sucesso"
                />
              )}
            </StyledFormControl>
          </form>
        </Paper>
      </Fragment>
    );
  }
}

NewIncidentPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NewIncidentPage);
