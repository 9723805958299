const styles = theme => ({
  card: {
    width: 350,
    margin: theme.spacing(2)
  },
  cardContent: {
    minheight: 300
  },
  avatar: {
    margin: '0 auto',
    width: 100,
    height: 100,
    marginBottom: theme.spacing(2)
  },
  delete: {
    justifyContent: 'center'
  },
  title: {
    fontSize: '18px'
  }
});

export default styles;
