import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { BrowserRouter, Route } from 'react-router-dom';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import reducer from './reducers';
import setAuthorizationHeader from './utils/setAuthorizationHeader';
import { fetchCurrentUser, userFetched } from './actions/user';

import { Cookies } from 'react-cookie';

const store = createStore(reducer, composeWithDevTools(applyMiddleware(thunk)));

if (localStorage.userToken) {
  setAuthorizationHeader(localStorage.userToken);
  store.dispatch(fetchCurrentUser());
} else {
  const cookies = new Cookies();
  const userToken = cookies.get('userToken');
  if (!userToken) {
    store.dispatch(userFetched({}));
  } else {
    setAuthorizationHeader(userToken);
    store.dispatch(fetchCurrentUser());
  }
}

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <Route component={App} />
    </Provider>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
