import React, { Component, Fragment } from "react";
import TopNavigation from "../../navigation/TopNavigation";
import PropTypes from "prop-types";
import api from "../../../api";
import {
  Select as MaterialSelect,
  MenuItem,
  InputLabel,
  withStyles,
  TextField,
  Button,
  Paper,
  Typography,
} from "@material-ui/core";
import Select from "react-select";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider, DatePicker } from "material-ui-pickers";
import InlineError from "../../messages/InlineError";
import { MySnackbar } from "../../messages/MySnackBar";
import {
  StyledFormControl,
  StyledFormControlFullWdith,
} from "../../../css/styled";

import styles from "./styles";
import moment from "moment";
import "moment/locale/pt-br";

moment.locale("pt-br");

export class NewTeacherIncident extends Component {
  state = {
    data: {
      date: moment.utc(),
      causeId: "",
      description: "",
      teacherId: "",
    },

    errors: {},
    causes: [],
    teachers: [],
    loading: false,
    success: false,
  };

  async componentDidMount() {
    const causes = await api.teacherIncident.fetchCauses();
    const data = await api.teacherIncident.fetchTeachers();

    let teachers = [];
    data.map((teacher) =>
      teachers.push({ label: teacher.name, value: teacher.codProf })
    );

    this.setState({ causes, teachers });
  }

  onChange = (e) =>
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });

  onDateChange = (date) =>
    this.setState({ data: { ...this.state.data, date } });

  onTeacherChange = (data) => {
    const teacherId = data ? data.value : "";
    this.setState({
      data: { ...this.state.data, teacherId },
    });
  };

  validate = (data) => {
    const errors = {};
    if (!data.causeId) errors.causeId = "Deve ser preenchido";
    if (!data.description) errors.description = "Deve ser preenchido";
    if (!data.teacherId) errors.teacherId = "Deve ser preenchido";
    return errors;
  };

  onSubmit = (e) => {
    e.preventDefault();
    const { data } = this.state;
    const errors = this.validate(data);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      this.setState({ loading: true });

      api.teacherIncident
        .store(data)
        .then(() => {
          this.setState({ success: true });
          setTimeout(function () {
            window.location.reload();
          }, 2000);
        })
        .catch(() => {
          errors.global = "Erro ao gravar. Tente novamente, por favor.";
          this.setState({ errors, loading: false });
        });
    }
  };

  render() {
    const { classes } = this.props;
    const { data, errors, teachers, causes } = this.state;

    return (
      <Fragment>
        <TopNavigation history={this.props.history} />
        <Paper className={classes.root} elevation={1}>
          <Typography variant="h5" component="h3">
            Novo Chamado para Professor
          </Typography>

          <form className={classes.form} onSubmit={this.onSubmit}>
            <StyledFormControl>
              <MuiPickersUtilsProvider
                utils={MomentUtils}
                locale="pt-br"
                moment={moment}
              >
                <DatePicker
                  autoOk
                  format={"DD/MM/YYYY"}
                  value={data.date}
                  label="Data"
                  margin="normal"
                  onChange={this.onDateChange}
                />
              </MuiPickersUtilsProvider>
            </StyledFormControl>

            <StyledFormControl>
              <InputLabel htmlFor="causeId">Motivo</InputLabel>
              <MaterialSelect
                value={data.causeId}
                inputProps={{
                  name: "causeId",
                  id: "causeId",
                }}
                onChange={this.onChange}
              >
                {causes.map((item) => (
                  <MenuItem
                    key={item.idteacher_cause}
                    value={item.idteacher_cause}
                  >
                    {item.name}
                  </MenuItem>
                ))}
              </MaterialSelect>
              {errors.causeId && <InlineError text={errors.causeId} />}
            </StyledFormControl>

            <StyledFormControlFullWdith>
              <InputLabel htmlFor="teacherId">Teacher</InputLabel>
              <Select
                inputProps={{
                  id: "teacherId",
                  name: "teacherId",
                }}
                onChange={this.onTeacherChange}
                placeholder="Professor"
                options={teachers}
              />

              {errors.teacherId && <InlineError text={errors.teacherId} />}
            </StyledFormControlFullWdith>

            <StyledFormControlFullWdith>
              <TextField
                value={data.description}
                id="description"
                name="description"
                label="Descrição"
                multiline
                onChange={this.onChange}
              />
              {errors.description && <InlineError text={errors.description} />}
            </StyledFormControlFullWdith>

            <StyledFormControl>
              <Button
                className={classes.submit}
                type="submit"
                color="primary"
                variant="contained"
                disabled={this.state.loading}
              >
                SALVAR
              </Button>
              {errors.global && (
                <MySnackbar
                  onClose={this.handleClose}
                  open={!!errors.global}
                  variant="error"
                  className={classes.globalError}
                  message={errors.global}
                />
              )}
              {this.state.success && (
                <MySnackbar
                  onClose={this.handleClose}
                  open={true}
                  variant="success"
                  className={classes.success}
                  message="Salvo com Sucesso"
                />
              )}
            </StyledFormControl>
          </form>
        </Paper>
      </Fragment>
    );
  }
}

NewTeacherIncident.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NewTeacherIncident);
