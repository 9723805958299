const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    width: 'auto',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    marginTop: 20,
    [theme.breakpoints.up(600 + theme.spacing(3 * 2))]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },

  form: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'baseline'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
    width: '40%',
    flexGrow: 1
  },
  fullWidth: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: '80%',
    flexGrow: 1
  },
  globalError: {
    marginTop: 20
  },
  success: {
    marginTop: 20
  },
  submit: {
    width: 250,
    margin: '0 auto'
  }
});

export default styles;
