import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import TopNavigation from "../../navigation/TopNavigation";
import {
  Select as MaterialSelect,
  withStyles,
  Typography,
  Paper,
  InputLabel,
  MenuItem,
  Button,
} from "@material-ui/core";
import Select from "react-select";
import Loader from "react-loader";
import {
  StyledFormControl,
  StyledFormControlFullWdith,
} from "../../../css/styled";
import { MuiPickersUtilsProvider, DatePicker } from "material-ui-pickers";
import MomentUtils from "@date-io/moment";

import { Link } from "./styled";

import styles from "./styles";

import moment from "moment";
import "moment/locale/pt-br";
import api from "../../../api";
import TeacherIncidentItem from "./TeacherIncidentItem";

moment.locale("pt-br");

class TeacherIncidentsFilter extends Component {
  state = {
    incidents: [],
    loading: false,
    filter: {
      causeId: "",
      teacherId: "",
      startDate: moment(moment.utc().format("YYYY") + "-01-01").utc(),
      endDate: moment(moment.utc().format("YYYY") + "-12-31").utc(),
    },
    causes: [],
    typingTimeout: 0,
    teachers: [],
  };

  async componentDidMount() {
    const causes = await api.teacherIncident.fetchCauses();
    const data = await api.teacherIncident.fetchTeachers();

    let teachers = [];
    data.map((teacher) =>
      teachers.push({ label: teacher.name, value: teacher.codProf })
    );

    this.setState({ causes, teachers });
  }

  onDateChange = (param, date) => {
    this.setState({
      filter: { ...this.state.filter, [param]: date },
    });
  };

  onChange = (e) => {
    this.setState({
      filter: { ...this.state.filter, [e.target.name]: e.target.value },
    });
  };

  onTeacherChange = (teacher) => {
    this.setState({
      filter: { ...this.state.filter, teacherId: teacher.value },
    });
  };

  onSubmit = async (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
    });

    const { filter } = this.state;

    try {
      const incidents = await api.teacherIncident.fetchWithFilter({
        startDate: filter.startDate.format("YYYY-MM-DD"),
        endDate: filter.endDate.format("YYYY-MM-DD"),
        causeId: filter.causeId,
        teacherId: filter.teacherId,
      });

      localStorage.setItem(
        "@Censachamados:teacherIncidents",
        JSON.stringify(incidents)
      );
      localStorage.setItem(
        "@Censachamados:teacherStartDate",
        filter.startDate.format("YYYY-MM-DD")
      );

      localStorage.setItem(
        "@Censachamados:teacherEndDate",
        filter.endDate.format("YYYY-MM-DD")
      );

      console.log(incidents);

      this.setState({ incidents, loading: false });
    } catch (err) {
      this.setState({ incidents: [], loading: false });
    }
  };

  reset = () => {
    this.setState({
      filter: {
        causeId: "",
        studentId: "",
        startDate: moment(moment.utc().format("YYYY") + "-01-01").utc(),
        endDate: moment(moment.utc().format("YYYY") + "-12-31").utc(),
      },
    });
  };

  render() {
    const { classes } = this.props;
    const { incidents, filter, causes, teachers } = this.state;
    return (
      <Fragment>
        <TopNavigation history={this.props.history} />
        <Paper className={classes.root}>
          <form className={classes.form} onSubmit={this.onSubmit}>
            <StyledFormControl>
              <MuiPickersUtilsProvider
                utils={MomentUtils}
                locale="pt-BR"
                moment={moment}
              >
                <DatePicker
                  autoOk
                  format="DD/MM/YYYY"
                  value={filter.startDate}
                  label="Periodo Inicial"
                  margin="normal"
                  onChange={(date) => this.onDateChange("startDate", date)}
                />
              </MuiPickersUtilsProvider>
            </StyledFormControl>
            <StyledFormControl>
              <MuiPickersUtilsProvider
                utils={MomentUtils}
                locale="pt-BR"
                moment={moment}
              >
                <DatePicker
                  autoOk
                  format="DD/MM/YYYY"
                  value={filter.endDate}
                  label="Periodo Final"
                  margin="normal"
                  onChange={(date) => this.onDateChange("endDate", date)}
                />
              </MuiPickersUtilsProvider>
            </StyledFormControl>

            <StyledFormControl>
              <InputLabel htmlFor="causeId">Motivo</InputLabel>
              <MaterialSelect
                value={filter.causeId}
                inputProps={{
                  name: "causeId",
                  id: "causeId",
                }}
                onChange={this.onChange}
              >
                {causes.map((item) => (
                  <MenuItem
                    key={item.idteacher_cause}
                    value={item.idteacher_cause}
                  >
                    {item.name}
                  </MenuItem>
                ))}
              </MaterialSelect>
            </StyledFormControl>

            <StyledFormControlFullWdith>
              <InputLabel htmlFor="teacherId">Teacher</InputLabel>
              <Select
                inputProps={{
                  id: "teacherId",
                  name: "teacherId",
                }}
                onChange={this.onTeacherChange}
                placeholder="Professor"
                options={teachers}
              />
            </StyledFormControlFullWdith>

            <StyledFormControl>
              <Button
                onClick={this.reset}
                className={classes.submit}
                type="button"
                color="primary"
                variant="contained"
                disabled={this.state.loading}
              >
                LIMPAR
              </Button>
            </StyledFormControl>

            <StyledFormControl>
              <Button
                className={classes.submit}
                type="submit"
                color="primary"
                variant="contained"
                disabled={this.state.loading}
              >
                PESQUISAR
              </Button>
            </StyledFormControl>

            {incidents.length > 0 && (
              <StyledFormControl>
                <Link href="relatorio-professores" target="_blank">
                  Relatório
                </Link>
              </StyledFormControl>
            )}
          </form>
        </Paper>

        <Loader loaded={!this.state.loading}>
          <Typography variant="h4" component="h1" className={classes.title}>
            Chamados
          </Typography>
          {incidents.length === 0 && (
            <Typography variant="h6" component="h2" className={classes.title}>
              Nenhum registro encontrado!
            </Typography>
          )}
          <div className={classes.incidents}>
            {incidents.map((item) => (
              <TeacherIncidentItem
                incident={item}
                key={item.idteacher_incident}
              />
            ))}
          </div>
        </Loader>
      </Fragment>
    );
  }
}

TeacherIncidentsFilter.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TeacherIncidentsFilter);
