import styled from 'styled-components';
import { Table as DefaultTable } from '../../styles';

export const CellTeacher = styled.td`
  color: red;
  font-size: 18px;
  padding: 10px 20px !important;
`;

export const Table = styled(DefaultTable)`
  margin-bottom: 30px;
`;
