import api from "../api";
import { USER_LOGGED_IN, USER_LOGGED_OUT } from "../types";
import setAuthorizationHeader from "../utils/setAuthorizationHeader";
import { Cookies } from "react-cookie";

export const userLoggedIn = (user) => ({
  type: USER_LOGGED_IN,
  user,
});

export const userLoggedOut = () => ({
  type: USER_LOGGED_OUT,
});

export const login = (username, password) => (dispatch) =>
  api.user.login(username, password).then((user) => {
    localStorage.userToken = user.token;
    const cookies = new Cookies();
    cookies.set("userToken", user.token, { path: "/" });
    setAuthorizationHeader(user.token);
    dispatch(userLoggedIn(user));
  });

export const logout = () => (dispatch) => {
  localStorage.removeItem("userToken");
  const cookies = new Cookies();
  cookies.remove("userToken");
  dispatch(userLoggedOut());
};
