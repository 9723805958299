import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import TopNavigation from '../../navigation/TopNavigation';
import {
  withStyles,
  Typography,
  Paper,
  InputLabel,
  Select,
  MenuItem,
  Button
} from '@material-ui/core';
import IncidentListItem from '../../pure/IncidentListItem';
import IncidentClassListItem from '../../pure/IncidentClassListItem';
import Loader from 'react-loader';
import {
  StyledFormControl,
  StyledFormControlFullWdith,
  StyledFormControlBlack
} from '../../../css/styled';
import { connect } from 'react-redux';
import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import AsyncSelect from 'react-select/async';
import { search } from '../../../actions/incident';

import { Link } from './styles';

import moment from 'moment';
import 'moment/locale/pt-br';
import api from '../../../api';

moment.locale('pt-br');

const styles = theme => ({
  incidents: {
    display: 'flex',
    alignItens: 'center',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  title: {
    textAlign: 'center',
    padding: theme.spacing(2),
    color: '#fff',
    fontWeight: 'bold'
  },
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    width: 'auto',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    marginTop: 20,
    [theme.breakpoints.up(600 + theme.spacing(3 * 2))]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  form: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'baseline'
  }
});

class IncidentsFilter extends Component {
  state = {
    incidents: [],
    loading: false,
    currentPerlet: moment.utc().format('YYYY'),
    filter: {
      cause: '',
      class: '',
      idturmadisc: '',
      startDate: moment(moment.utc().format('YYYY') + '-01-01').utc(),
      endDate: moment(moment.utc().format('YYYY') + '-12-31').utc()
    },
    causes: [],
    turmadiscs: [],
    classes: [],
    subjects: [],
    typingTimeout: 0,
    students: [],
    student: null
  };

  componentDidMount = () => {
    api.system
      .currentPerlet()
      .then(res => this.setState({ currentPerlet: res }));
    api.incident.fetchCauses().then(causes => this.setState({ causes }));
    api.incident.fetchTeacherClasses().then(turmadiscs => {
      this.setState({ turmadiscs });
      const classes = turmadiscs.filter((elem, index, self) => {
        for (let x = 0; x < self.length; x++) {
          if (elem.class === self[x].class) {
            return self.indexOf(elem) === x;
          }
        }
        return false;
      });
      this.setState({ classes });
    });
  };

  renderItem = item => {
    return item.type === 'individual' ? (
      <IncidentListItem key={item.idincident} incident={item} />
    ) : (
      <IncidentClassListItem key={item.idincident} incident={item} />
    );
  };

  onDateChange = (param, date) => {
    this.setState({
      filter: { ...this.state.filter, [param]: date }
    });
  };

  onChange = e => {
    this.setState({
      filter: { ...this.state.filter, [e.target.name]: e.target.value }
    });
  };

  onClassChange = e => {
    const subjects = this.state.turmadiscs.filter(
      elem => elem.class === e.target.value
    );
    this.setState({
      subjects,
      students: [],
      filter: { ...this.state.filter, idturmadisc: '', class: e.target.value }
    });
  };

  onSubjectChange = e => {
    this.setState({
      filter: { ...this.state.filter, [e.target.name]: e.target.value }
    });
  };

  studentsOptions = (inputValue, callback) => {
    if (this.state.typingTimeout) {
      clearTimeout(this.state.typingTimeout);
    }

    this.setState({
      typingTimeout: setTimeout(
        () =>
          api.incident
            .fetchStudents(inputValue, '')
            .then(students => callback(students)),
        1500
      )
    });

    return null;
  };

  onStudentChange = student => {
    this.setState({ student });

    if (student != null) {
      this.setState({
        filter: { ...this.state.filter, student: student.value }
      });
    } else {
      this.setState({
        filter: { ...this.state.filter, student: '' }
      });
    }
  };

  onSubmit = e => {
    e.preventDefault();
    this.setState({
      loading: true
    });

    const filter = this.state.filter;

    this.props
      .search(filter)
      .then(() => this.setState({ loading: false }))
      .catch(() => this.setState({ loading: false }));
  };

  reset = () => {
    this.setState({
      filter: {
        cause: '',
        class: '',
        idturmadisc: '',
        startDate: moment(moment.utc().format('YYYY') + '-01-01').utc(),
        endDate: moment(moment.utc().format('YYYY') + '-12-31').utc()
      },
      student: null
    });
  };

  render() {
    const { classes, incidents } = this.props;
    const { filter } = this.state;
    return (
      <Fragment>
        <TopNavigation history={this.props.history} />
        <Paper className={classes.root}>
          <form className={classes.form} onSubmit={this.onSubmit}>
            <StyledFormControl>
              <MuiPickersUtilsProvider
                utils={MomentUtils}
                locale="pt-BR"
                moment={moment}
              >
                <DatePicker
                  autoOk
                  format="DD/MM/YYYY"
                  value={filter.startDate}
                  label="Periodo Inicial"
                  margin="normal"
                  onChange={date => this.onDateChange('startDate', date)}
                />
              </MuiPickersUtilsProvider>
            </StyledFormControl>
            <StyledFormControl>
              <MuiPickersUtilsProvider
                utils={MomentUtils}
                locale="pt-BR"
                moment={moment}
              >
                <DatePicker
                  autoOk
                  format="DD/MM/YYYY"
                  value={filter.endDate}
                  label="Periodo Final"
                  margin="normal"
                  onChange={date => this.onDateChange('endDate', date)}
                />
              </MuiPickersUtilsProvider>
            </StyledFormControl>
            <StyledFormControl>
              <InputLabel>Motivo</InputLabel>
              <Select
                value={filter.cause}
                inputProps={{
                  name: 'cause',
                  id: 'cause'
                }}
                onChange={this.onChange}
              >
                {this.state.causes.map((item, index) => (
                  <MenuItem key={index} value={item.idcause}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </StyledFormControl>

            <StyledFormControlFullWdith>
              <InputLabel htmlFor="class">Turma</InputLabel>
              <Select
                value={filter.class}
                inputProps={{ name: 'class', id: 'class' }}
                onChange={this.onClassChange}
              >
                {this.state.classes.map((item, index) => (
                  <MenuItem key={index} value={item.class}>
                    {item.class}
                  </MenuItem>
                ))}
              </Select>
            </StyledFormControlFullWdith>

            <StyledFormControlFullWdith>
              <InputLabel htmlFor="idturmadisc">Disciplina</InputLabel>
              <Select
                value={filter.idturmadisc}
                inputProps={{ name: 'idturmadisc', id: 'idturmadisc' }}
                onChange={this.onSubjectChange}
              >
                {this.state.subjects.map((item, index) => (
                  <MenuItem key={index} value={item.idturmadisc}>
                    {item.subject}
                  </MenuItem>
                ))}
              </Select>
            </StyledFormControlFullWdith>

            <StyledFormControlBlack>
              <InputLabel htmlFor="student">Aluno</InputLabel>
              <AsyncSelect
                inputProps={{
                  id: 'student',
                  name: 'student'
                }}
                onChange={this.onStudentChange}
                isClearable
                cacheOptions
                defaultOptions
                loadOptions={this.studentsOptions}
                placeholder="Aluno"
                value={this.state.student}
              />
            </StyledFormControlBlack>

            <StyledFormControl>
              <Button
                onClick={this.reset}
                className={classes.submit}
                type="button"
                color="primary"
                variant="contained"
                disabled={this.state.loading}
              >
                LIMPAR
              </Button>
            </StyledFormControl>

            <StyledFormControl>
              <Button
                className={classes.submit}
                type="submit"
                color="primary"
                variant="contained"
                disabled={this.state.loading}
              >
                PESQUISAR
              </Button>
            </StyledFormControl>

            {incidents.length > 0 && (
              <StyledFormControl>
                <Link href="relatorio" target="_blank">
                  Relatório
                </Link>
              </StyledFormControl>
            )}
          </form>
        </Paper>

        <Loader loaded={!this.state.loading} color="#fff">
          <Typography variant="h4" component="h1" className={classes.title}>
            Chamados
          </Typography>
          {incidents.length === 0 && (
            <Typography variant="h6" component="h2" className={classes.title}>
              Nenhum registro encontrado!
            </Typography>
          )}
          <div className={classes.incidents}>
            {incidents.map((item, index) => this.renderItem(item))}
          </div>
        </Loader>
      </Fragment>
    );
  }
}

IncidentsFilter.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  incidents: state.incident.incidents
});

export default connect(
  mapStateToProps,
  { search }
)(withStyles(styles)(IncidentsFilter));
