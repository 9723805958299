import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import Paper from "@material-ui/core/Paper";
import withStyles from "@material-ui/core/styles/withStyles";

import { login } from "../../actions/auth";
import "../../css/Login.css";

import { MySnackbar } from "../messages/MySnackBar";

const styles = (theme) => ({
  main: {
    width: "auto",
    display: "block", // Fix IE 11 issue.
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.up(400 + theme.spacing(3 * 2))]: {
      width: 400,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
      3
    )}px`,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(3),
  },
  margin: {
    marginTop: theme.spacing(3),
  },
});

class LoginPage extends React.Component {
  state = {
    username: "",
    password: "",
    error: "",
    loading: false,
  };

  submit = (e) => {
    e.preventDefault();

    this.setState({ loading: true, error: "" });

    this.props
      .login(this.state.username, this.state.password)
      .then(() => this.props.history.push("/novo-chamado"))
      .catch((err) => {
        console.log(err.message);
        let { message } = err;
        if (!message) {
          message = "Tente novamente mais tarde!";
        }

        this.setState({
          loading: false,
          error: message,
        });
      });
  };

  onChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
    });

  handleClose = () => this.setState({ error: "" });

  render() {
    const { classes } = this.props;
    const { username, password, error, loading } = this.state;

    return (
      <main id="login" className={classes.main}>
        <CssBaseline />
        <Paper className={classes.paper}>
          <img
            src={require("../../images/censa_chamado_logo.png")}
            alt="Logo Censa"
            title="Logo Censa"
          />
          <form
            data-testid="login-form"
            className={classes.form}
            onSubmit={this.submit}
          >
            <FormControl margin="normal" fullWidth>
              <InputLabel htmlFor="username">usuário</InputLabel>
              <Input
                id="username"
                name="username"
                autoComplete="username"
                value={username}
                onChange={this.onChange}
                autoFocus
              />
            </FormControl>
            <FormControl margin="normal" fullWidth>
              <InputLabel htmlFor="password">senha</InputLabel>
              <Input
                name="password"
                type="password"
                id="password"
                value={password}
                onChange={this.onChange}
                autoComplete="current-password"
              />
            </FormControl>
            <Button
              disabled={loading}
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              ENTRAR
            </Button>
            {error && (
              <MySnackbar
                onClose={this.handleClose}
                open={!!error}
                variant="error"
                className={classes.margin}
                message={error}
              />
            )}
          </form>
        </Paper>
      </main>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired,
  login: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { login })(
  withStyles(styles)(LoginPage)
);
