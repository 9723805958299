import styled from 'styled-components';
import { Link as DefaultLink } from 'react-router-dom'
import { FormControl as DefaultFormControl, Button as DefaultButton } from '@material-ui/core';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { darken } from 'polished'

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
`;

export const Paper = styled.div`
  margin: 20px 20px;
  border-radius: 8px;
  box-sizing: border-box;

  size: auto;
  width: ${({ width }) => width ? width : 'initial'};

  padding: 20px;
  background: white;
  text-align: center;
`;

export const Title = styled.h1`
  margin-top: 0;
`;

export const Link = styled(DefaultLink)`
  display: block;
  color: #5d9acf;
  font-size: 16px;
  margin-bottom: 5px;
`;

export const FormControl = styled(DefaultFormControl)`
  && {
    margin: ${({ theme }) => theme.spacing(1)}px;
    min-width: 200px;
    width: ${({ width }) => width ? width : '95%'};
    flex-grow: 1;
    text-align: left;
  }
`;

export const Button = styled(DefaultButton)`
 && {
  width: 250px;
  margin: 0 auto;
 }
`;

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;

  td {

    border: solid 1px #1d1d1d;
    padding: 5px;
  }
`;

export const Buttons = styled.div`
  position: relative;
`;

export const Download = styled(ReactHTMLTableToExcel)`
  margin-bottom: 20px;
  background: #5d9acf;
  color: white;
  font-size: 15px;
  font-weight: bold;
  border: 0;
  border-radius: 5px;
  padding: 8px 20px;

  transform: background 0.2s;

  &:hover {
    background: ${darken(0.2, '#5d9acf')}
  }

  @media print {
    display: none;
  }

`;

export const BackButton = styled.button`
  position: absolute;
  right: 0;
  background: white;
  color: #1d1d1d;
  font-size: 15px;
  border: solid 1px #1d1d1d;
  border-radius: 5px;
  padding: 8px 30px;

  transform: background 0.2s;

  &:hover {
    background: ${darken(0.2, '#fff')}
  }

  @media print {
    display: none;
  }
`;
