import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Select, MenuItem, InputLabel } from "@material-ui/core";
import qs from "qs";
import api from "../../../../api";
import { Container, Paper, Title, FormControl, Button } from "../styles";
import { MuiPickersUtilsProvider, DatePicker } from "material-ui-pickers";
import MomentUtils from "@date-io/moment";
import TopNavigation from "../../../navigation/TopNavigation";
import SimpleReport from "./SimpleReport";
import MultiReport from "./MultiReport";
import moment from "moment";
import "moment/locale/pt-br";

export default function ReportIncidentByTeacher({ history }) {
  const SIMPLE_REPORT_TYPE = "SIMPLE";
  const MULTI_REPORT_TYPE = "MULTI";

  const [filter, setFilter] = useState({
    startDate: moment(moment.utc().format("YYYY") + "-01-01").utc(),
    endDate: moment(moment.utc().format("YYYY") + "-12-31").utc(),
  });
  const [codturma, setCodturma] = useState("");
  const [classes, setClasses] = useState([]);
  const [teacherId, setTeacherId] = useState("");
  const [teachers, setTeachers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);
  const [reportType, setReportType] = useState(SIMPLE_REPORT_TYPE);

  const memorizedOnBackPress = useCallback(() => {
    setResult(null);
    setTeacherId("");
    setCodturma("");
    setTeachers([]);
  }, []);

  const memorizedTeacher = useMemo(() => {
    const teacher = teachers.find((item) => item.codprof === teacherId);
    return teacher ? teacher.nome : "";
  }, [teacherId, teachers]);

  useEffect(() => {
    async function load() {
      const { data } = await api.axios.get("/reports/classes");
      setClasses(data);
    }
    load();
  }, []);

  useEffect(() => {
    async function load() {
      const { data } = await api.axios.get(`/reports/teachers/${codturma}`);
      setTeachers(data);
    }
    if (!codturma) return;
    setTeachers([]);
    setTeacherId("");
    load();
  }, [codturma]);

  async function handleSimpleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    setReportType(SIMPLE_REPORT_TYPE);
    try {
      const { data } = await api.axios.get(
        "reports/incidents-by-teacher?" +
          qs.stringify({
            teacherId,
            codturma,
            startDate: filter.startDate.format("YYYY-MM-DD"),
            endDate: filter.endDate.format("YYYY-MM-DD"),
          })
      );
      setResult(data);
    } finally {
      setLoading(false);
    }
  }

  async function handleMultiSubmit(e) {
    e.preventDefault();
    setLoading(true);
    setReportType(MULTI_REPORT_TYPE);
    try {
      const { data } = await api.axios.get(
        `reports/incidents-by-class?${qs.stringify({
          teacherId,
          codturma,
          startDate: filter.startDate.format("YYYY-MM-DD"),
          endDate: filter.endDate.format("YYYY-MM-DD"),
        })}`
      );
      setResult(data);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Container>
      <TopNavigation history={history} />
      <Paper width={result ? "initial" : "600px"}>
        <Title>Relatório Chamados por Professor</Title>
        {result ? (
          reportType === SIMPLE_REPORT_TYPE ? (
            <SimpleReport
              codturma={codturma}
              teacher={memorizedTeacher}
              data={result}
              onBackPress={memorizedOnBackPress}
            />
          ) : (
            <MultiReport
              codturma={codturma}
              data={result}
              onBackPress={memorizedOnBackPress}
            />
          )
        ) : (
          <form>
            <FormControl style={{ flexDirection: "row" }}>
              <FormControl>
                <MuiPickersUtilsProvider
                  utils={MomentUtils}
                  locale="pt-BR"
                  moment={moment}
                >
                  <DatePicker
                    autoOk
                    format="DD/MM/YYYY"
                    value={filter.startDate}
                    label="Periodo Inicial"
                    margin="normal"
                    onChange={(date) =>
                      setFilter({ ...filter, startDate: date })
                    }
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
              <FormControl>
                <MuiPickersUtilsProvider
                  utils={MomentUtils}
                  locale="pt-BR"
                  moment={moment}
                >
                  <DatePicker
                    autoOk
                    format="DD/MM/YYYY"
                    value={filter.endDate}
                    label="Periodo Final"
                    margin="normal"
                    onChange={(date) => setFilter({ ...filter, endDate: date })}
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
            </FormControl>
            <FormControl>
              <InputLabel htmlFor="class">Turma</InputLabel>
              <Select
                value={codturma}
                inputProps={{ name: "class", id: "class" }}
                onChange={(e) => setCodturma(e.target.value)}
              >
                {classes.map((item, index) => (
                  <MenuItem key={index} value={item.CODTURMA}>
                    {item.CODTURMA}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel htmlFor="class">Professor</InputLabel>
              <Select
                value={teacherId}
                inputProps={{ name: "teacher", id: "teacher" }}
                onChange={(e) => setTeacherId(e.target.value)}
              >
                {teachers.map((item, index) => (
                  <MenuItem key={index} value={item.codprof}>
                    {item.nome}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <Button
                type="button"
                color="primary"
                variant="contained"
                disabled={!teacherId || loading}
                onClick={handleSimpleSubmit}
              >
                GERAR
              </Button>
            </FormControl>

            <FormControl>
              <Button
                type="button"
                color="primary"
                variant="contained"
                disabled={!codturma || loading}
                onClick={handleMultiSubmit}
              >
                GERAR PARA TODOS
              </Button>
            </FormControl>
          </form>
        )}
      </Paper>
    </Container>
  );
}
