import styled from 'styled-components';
import { FormControl } from '@material-ui/core';

export const StyledFormControl = styled(FormControl)`
  && {
    margin: ${props => props.theme.spacing(1)}px;
    min-width: 200px;
    width: 40%;
    flex-grow: 1;
  }
`;

export const StyledFormControlFullWdith = styled(StyledFormControl)`
  && {
    width: 80%;
  }
`;

export const StyledFormControlBlack = styled(StyledFormControl)`
  && {
    width: 80%;
    color: #000;
  }
`;

export const FlexDiv = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

export const IncidentsDiv = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;
