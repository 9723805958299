import React, { Component } from 'react';
import { Link, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

const style = theme => ({
  brand: {
    backgroundColor: '#313130',
    padding: 20,
    textAlign: 'center',
  },
  error404: {
    color: '#0f284a',
    fontSize: 40,
    lineHeight: '45px',
    fontWeight: 'bold',
    marginTop: 20,
    textAlign: 'center',
  },
  message: {
    textAlign: 'center',
    marginTop: 20,
    color: 'white',
  },
  link: {
    color: '#0f284a',
  },
});

class NotFoundPage extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div>
        <div className={classes.brand}>
          <img
            src={require('../../images/censa_chamado_logo_horizontal.png')}
            alt="Logo Censa Chamados"
            title="Logo Censa Chamados"
          />
        </div>
        <div className={classes.error404}>Ops! ERRO 404.</div>
        <div className={classes.message}>
          A página solicitada não foi encontrada. <br />
          <Link className={classes.link} href="/">
            Clique aqui
          </Link>{' '}
          para voltar a página inicial.
        </div>
      </div>
    );
  }
}

NotFoundPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(style)(NotFoundPage);
