import React from 'react';
import { Table, CellTeacher } from './styles';

export default function Result({ codturma, teacher, data }) {

  function causeNumber(student, cause) {
    const incidents = data.incidents.find(incident =>
      incident.idcause === cause.idcause && incident.ra === student.ra)

    if (!incidents) return 0;
    return incidents.qtde;
  }

  return (
    <Table cellPadding={0} cellSpacing={0} id='table-to-xls-report3'>
      <tbody>
        <tr>
          <td>&nbsp;</td>
          <CellTeacher colSpan={data.causes.length}>{`${codturma} - ${teacher}`}</CellTeacher>
        </tr>
        <tr>
          <td>&nbsp;</td>
          {data.causes.map(cause =>
            <td key={cause.idcause}>
              {cause.name}
            </td>)
          }
        </tr>
        {data.students.map(student =>
          <tr key={student.ra}>
            <td>{student.ra} - {student.nome}</td>
            {data.causes.map(cause =>
              <td>
                {causeNumber(student, cause)}
              </td>
            )}
          </tr>

        )}
      </tbody>
    </Table>
  )
}
