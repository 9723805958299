import React from 'react'

import { Container, Paper, Title, Link } from './styles'
import TopNavigation from '../../navigation/TopNavigation'

export default function Reports() {
  return (
    <Container>
      <TopNavigation />
      <Paper width='600px'>
        <Title>Relatórios</Title>

        <Link to='/relatorios/aluno-motivo-falta' target='_blank'>
          Relatório Aluno Motivo/Falta
        </Link>
        <Link to='/relatorios/chamados-por-professor' target='_blank'>
          Relatório Chamados por Professor
        </Link>
        <Link to='/relatorios/chamada-por-turma' target='_blank'>
          Relatório Chamada por Turma
        </Link>
        <Link to='/relatorios/faltas-por-professor' target='_blank'>
          Relatório Faltas por Professor
        </Link>
      </Paper>
    </Container>
  )
}
