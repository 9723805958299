import styled from 'styled-components';

export const Container = styled.div`
  size: auto;
  max-width: 210mm;
  padding: 15mm 10mm 10mm 10mm;
  margin: 0 auto;
  background: white;
  text-align: center;
  height: 100%;

  img {
    margin-bottom: 30px;
  }
`;

export const Text = styled.p`
  padding: 10px;
  text-align: left;
`;

export const Signature = styled.div`
  margin-top: 80px;
  border-top: solid 1px;
`;

export const Item = styled.div`
  padding: 0;
  text-align: left;
  font-weight: ${props => (props.bold ? 'bold' : 'normal')};
  margin-left: ${props => (props.margin ? props.margin * 50 : 0)}px;
`;
